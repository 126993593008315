import React from "react"
import Link from "../components/link"

const Footer = () => {
    return (
        <div className="c-footer">
           
            <div className="c-footer__copyright">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xs">
                            <p>&copy; Copyright {new Date().getFullYear()} | Sunshine Offers All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;