import React, { Component, useEffect } from "react"
import Link from '../components/link'
import Button from '../components/button'
import Logo from '../images/sso-logo.png'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerOpen: false,
            showCTA: true
        }

        this.toggleHamburger = this.toggleHamburger.bind(this);
        this.toggleCTA = this.toggleCTA.bind(this);
    }

    componentDidMount(){
        // window.addEventListener('scroll', this.onScroll, false);
        // (this.state.hamburgerOpen) ? (document.body.classList.add('is-open')) : (document.body.classList.remove('is-open'));
        // if (window.location.pathname.includes('questionnaire') || window.location.pathname.includes('Questionnaire')) this.toggleCTA();
    }

    // onScroll() {
    //     if(window.pageYOffset >= 200) {
    //         document.body.classList.add('is-sticky');
    //         document.querySelector('.c-navigation').classList.add('c-navigation--sticky');
    //     } else {
    //         document.body.classList.remove('is-sticky');
    //         document.querySelector('.c-navigation').classList.remove('c-navigation--sticky');
    //     }
    // }

    toggleHamburger() {
        this.setState({
            hamburgerOpen: !this.state.hamburgerOpen
        });
    }

    toggleCTA() {
        this.setState({
            showCTA: !this.state.showCTA
        });
    }

    render() {

        const createClassList = () => {
            let classList = 'c-header';
            if(this.state.hamburgerOpen) classList = classList + ' c-header--hamburger-menu-open'
            return classList;
        }

        const buttonComponent = (this.state.showCTA) ? (
            <Button
                type='primary'
                url='mailto:ryan@sunshine-offers.com'
                label='Contact Us'
                classNames='c-header__button c-header__button--desktop'
                externalURL
            />
        ) : null;


        
        const buttonComponentMobile = (this.state.showCTA) ? (
            <Button
                type='primary'
                url='mailto:ryan@sunshine-offers.com'
                label='Contact'
                classNames='c-header__button c-header__button--mobile'
                externalURL
            />
        ) : null;

        const navItemsDesktop = (
            <div className="g-col-xs-12 g-col-md-8 c-header__nav">
                <Link url="/about" classNames="c-header__link">About</Link>
                <Link url="/faq" classNames="c-header__link">FAQ</Link>
                <Link url="/contact" classNames="c-header__link">Contact</Link>
                {buttonComponent}
            </div>
        )

        const ctaOnly = (
            <div className="g-col-xs-6 g-col-md-8 c-header__nav">
                {buttonComponent}
                {buttonComponentMobile}
            </div>
        )

        const navItemsMobile = (
            <div className="g-col-xs-12 g-col-md-8 c-header__nav">
                <Link url="/about" classNames="c-header__link">About</Link>
                <Link url="/faq" classNames="c-header__link">FAQ</Link>
                <Link url="/contact" classNames="c-header__link">Contact</Link>
                {buttonComponentMobile}
                <div className='c-header__nav c-header__secondary'>
                    <Link url="/privacy-policy" classNames="c-header__link">Privacy Policy</Link>
                    <Link url="/terms-and-conditions" classNames="c-header__link">Terms &amp; Conditions</Link>
                </div>
            </div>
        )


        const hamburgerIcon = (
            <div className='g-col-xs c-header__hamburger-icon' onClick={this.toggleHamburger}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
        
        return (
            <div className={createClassList()}>
                <div className='c-header__wrapper'>
                    <div className="c-header__main-menu">
                        <div className="g-container">
                            <div className="g-row">
                                <div className="g-col-xs-6 g-col-md-4 c-header__logo">
                                    <Link url="/" image>
                                        <img src={Logo} alt="QuickyClaims logo" />
                                    </Link>
                                </div>
                                {/* {navItemsDesktop} */}
                                {/* {hamburgerIcon} */}
                                {ctaOnly}
                            </div>
                        </div>
                    </div>
                    {/* <div className='c-header__hamburger-menu'>
                        <div className='g-row c-header__hamburger-menu-content'>
                            {navItemsMobile}
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}


export default Header;