import React from "react"
import Link from '../components/link'

const Button = (
    {
        type, 
        url = false, 
        label, 
        target = '_self', 
        shadow = false, 
        externalURL = false, 
        large = false,
        medium = false, 
        classNames = false,
        linkClassNames = false,
        id = false, 
        onClick = false,
        promo = false
    }
) => {

    const createClassList = () => {
        let classList = 'c-button c-button--' + type;
        if(shadow) classList = classList + ' c-button--shadow';
        if(large) classList = classList + ' c-button--large';
        if(medium) classList = classList + ' c-button--medium';
        if(classNames) classList = classList + ' ' + classNames;
        return classList;
    }

    const linkComponent = (
        <Link 
            id={id}
            url={url}
            target={target}
            external={externalURL}
            promo={promo}
            onClick={onClick}
            classNames={linkClassNames}
        >{label}</Link>
    )

    return (
        <div className={createClassList()}>
            {linkComponent}
        </div>
    );
}

export default Button;